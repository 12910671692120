.billing-container {
}

.billing-container .billing-purchase-card {
  margin-bottom: 20px;
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
    rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
}
.billing-container .billing-purchase-card .ant-card-body {
  padding: 30px;
}
.billing-container .billing-form-card {
  margin-bottom: 20px;
}
.billing-container .billing-form-card .ant-card-body {
  padding: 20px;
}
.billing-container .documents-card .ant-card-body {
  padding: 20px;
}

.billing-container .purchase-info {
  padding: 10;
}

.billing-container .purchase-info .section-title {
  font-size: 1.4rem;
  color: #707070;
  margin-bottom: 0rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.products-content .section-title,
.documents .section-title {
  font-size: 1rem;
  color: #2c2c2c;
}

.billing-container .purchase-info .section-sub-title {
  display: flex;
  font-size: 1rem;
  color: #4a4a4a;
  margin-bottom: 4px;
  align-items: baseline;
}

.billing-container .billing-info .section-title {
  text-align: center;
  font-size: 1.1rem;
  color: #707070;
  margin-bottom: 0rem;
}

.documents-card .section-sub-title {
  text-align: center;
  font-size: 1.1rem;
  color: #707070;
  margin-bottom: 0rem;
}

.billing-container .purchase-info div {
  font-size: 0.8rem;
  font-weight: 400;
}
.billing-container .purchase-info div span,
.billing-container .purchase-info .purchase-info-product {
  font-size: 0.8rem;
  font-weight: 600;
}

.billing-container .billing-info input,
.billing-container .billing-info .ant-select-selector,
.billing-container .billing-info button {
  font-size: 1rem;
  font-weight: 400;
  height: 38px !important;
}

.billing-container .billing-info .vatid input {
  font-size: 1rem;
  font-weight: 400;
  height: 38px !important;
}

.billing-container .billing-info .ant-input-affix-wrapper {
  padding: 0px 11px;
}

.billing-container .billing-info .ant-row {
  padding-bottom: 2px;
}

.billing-container .billing-info .ant-row:last-child {
  padding-bottom: 0px;
}
.billing-container .billing-info .ant-form-item:last-child {
  margin-bottom: 0px;
}

.billing-container .billing-info .ant-form-item-explain-error {
  padding-bottom: 15px;
}

.billing-container .billing-info .ant-form-item-control .postal-code,
.billing-container .billing-info .ant-form-item-control .vatid,
.billing-container .billing-info .ant-form-item-control .phone {
  width: 100%;
  max-width: 300px;
}
.billing-container .billing-info .submit-button {
  background-color: #21ba45;
  border-color: #44bd60;
  font-size: 1.1rem;
  font-weight: 600;

  padding: 8px 20px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  height: 50px !important;
}

.billing-container .billing-info .submit-button:hover {
  background-color: #16ab39;
  border-color: #44bd60;
}

.billing-container .btn-product-access {
  background-color: #21ba45;
  border-color: #44bd60;
  font-size: 1.1rem;
  font-weight: 600;

  padding: 8px 20px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  height: 50px !important;
}

.billing-container .btn-product-access:hover {
  background-color: #16ab39;
  border-color: #44bd60;
}

.billing-container .btn-product-access:disabled {
  background-color: #aaaaaa;
  border-color: #adadad;
}

.billing-details .billing-info-line {
  display: flex-start;

  margin-bottom: 10px;
}

.billing-details .billing-info-list div {
  display: flex;
  margin-right: 10px;
  margin-bottom: 5px;
}

.billing-details .billing-info-list .billing-info-title {
  font-weight: 600;
  min-width: 100px;
  width: 100px;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}
.billing-details .billing-info-list .billing-info-value {
  min-width: 250px;
  width: 100%;
  border-bottom: 0.1em dotted;
}
.billing-details .billing-info-list .billing-info-no-value {
  font-size: 0.9rem;
  font-style: italic;
  color: #999;
}

.billing-container .documents-card .ant-table-wrapper {
  padding: 5px;
  padding-top: 0px;
}

.billing-container .product-access {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  text-align: right;
  right: 10px;
}

/**

MOBILE - TABLET

*/

.view-purchase-details-top {
  display: block;
}
.view-purchase-details-bottom {
  display: none;
}

.documents-table {
  padding-left: 0px !important;
  padding-right: 10px !important;
}
.documents .external-h4 {
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
}

.documents .external-h5 {
  font-size: 0.8rem;
  color: #707070;
  font-weight: 400;
}

@media only screen and (max-width: 1200px) {
  .billing-container .documents-card .ant-card-body {
    padding: 5px;
  }

  .product-content-card .ant-card-body {
    padding: 10px;
  }
  .documents-table {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .view-purchase-details-top {
    display: none;
  }
  .view-purchase-details-bottom {
    display: block;
    text-align: center;
  }
  .billing-details .billing-info-list li div {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .billing-details .billing-info-list li .billing-info-title {
    font-weight: 600;
    min-width: "50px";
    text-align: right;
  }
  .billing-details .billing-info-list li .billing-info-value {
    border-bottom: 0.1em dotted;
    min-width: "50px";
    min-width: auto;
  }
  .billing-container .purchase-info {
    text-align: center;
  }

  .billing-container .btn-product-access {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 10px 15px;
    height: auto !important;
  }

  .billing-container .billing-purchase-card .ant-card-body {
    padding: 15px;
  }

  .billing-container .product-access {
    position: relative;
    top: 0;
    transform: translateY(0);
    display: flex;
    justify-content: center;
    text-align: center;
    right: 0;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .billing-container .billing-form-card {
    margin-top: 40px;
  }

  .billing-container .purchase-info .section-sub-title {
    display: block;
    font-size: 1rem;
    color: #4a4a4a;
    margin-bottom: 4px;
  }

  .billing-container .purchase-info .section-title {
    font-size: 1.2rem;
    color: #707070;
    margin-bottom: 0rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .salespark-logo {
    text-align: center;
  }
}

.billing-form .ant-form-item {
  margin-bottom: 8px;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}
