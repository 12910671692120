.product-content-card {
  margin-bottom: 35px;
}

.product-content-card .products-content .ant-card-bordered {
  border: 2px solid #ed6c4c !important;
}
.product-content-card .products-content .products-content-item {
  font-size: 0.95rem;
  padding: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-content-card .products-content .event-content-item {
  font-size: 0.95rem;
  display: flex;
}

.clickable,
.clickable_hover {
  cursor: pointer;
}

.clickable_hover:hover {
  cursor: pointer;
  color: #3e79f7 !important;
}
